import React, { useState, useEffect, useCallback } from "react";
import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { light_orange } from "../../../utils/colors";
import styled from "styled-components";
import { SquareButton } from "../../../components/buttons/SquareButton";
import SaveModal from "../components/SaveModal";
import { getInitialColumnDefs } from "./components/initialColumnDefs";
import EditColumnsModal from "./components/editColumnsModal";
import DownloadMeterData from "../components/DownloadMeterData";
import {
  calculateAverageBlendedRate,
  checkForElectric,
} from "./meterDataOverview/utils";
import { useAuth } from "../../../store/AuthContext";
import {
  fetchColumnSelection,
  saveColumnSelection,
} from "../apiCalls/meterTable";

const GridContainer = styled.div`
  box-shadow: 0px 0px 3px 0px rgba(4, 4, 52, 0.09),
    0px 6px 12px 0px rgba(4, 4, 52, 0.02), 0px 4px 8px 0px rgba(4, 4, 52, 0.05);
  border-radius: 8px;
  height: Calc(100vh - 188px);
`;

const ButtonContainer = styled.div`
  display: flex;
`;

const AvgsContainer = styled.div`
  margin-top: -3px;
  text-align: right;
`;

const Avg = styled.div`
  font-size: 12px;
  text-align: right;
  font-weight: 400;
  line-height: 1.6;
`;

const DownloadButtonContainer = styled.div`
  margin-top: -42px;
  float: right;
  display: flex;
`;

function getRowStyle(params) {
  if (
    params.data.confidence < 0.9 ||
    params.data.usage === null ||
    params.data.usage === undefined
  ) {
    return { backgroundColor: light_orange };
  }
  return null; // Return null if no specific style is needed
}

const MeterDataTable = ({
  solarProductivity,
  saveRowData,
  name,
  preprocessedData,
  preprocessedMonthlyData,
  rawData,
  saveFilteredTableRows,
}) => {
  const { currentUser } = useAuth();
  const [gridApi, setGridApi] = useState(null);
  const [rowDataState, setRowDataState] = useState(
    JSON.parse(JSON.stringify(preprocessedData))
  );
  const [selectedRows, setSelectedRows] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [colDefs, setColDefs] = useState(
    getInitialColumnDefs(JSON.parse(JSON.stringify(preprocessedData)))
  );
  const [monthColDefs, setMonthColDefs] = useState(
    getInitialColumnDefs(JSON.parse(JSON.stringify(preprocessedMonthlyData)))
  );

  const [nonMonthColDefs, setNonMonthColDefs] = useState(
    getInitialColumnDefs(JSON.parse(JSON.stringify(preprocessedData)))
  );
  const [EditColumnsModalOpen, setEditColumnsModalOpen] = useState(false);
  const [monthly, setMonthly] = useState(false);
  const [averageBlendedRate, setAverageBlendedRate] = useState("0 $/kWh");
  const [weightedAverageBlendedRate, setWeightedAverageBlendedRate] =
    useState("0 $/kWh");
  const [displayedData, setDisplayedData] = useState(
    JSON.parse(JSON.stringify(preprocessedData))
  );
  const [openDownloadModal, setopenDownloadModal] = useState(false);

  const updateDisplayedData = useCallback(() => {
    if (gridApi) {
      const displayedRows = [];
      gridApi.forEachNodeAfterFilterAndSort((node) => {
        displayedRows.push(node.data);
      });
      setDisplayedData(displayedRows);
      saveFilteredTableRows(displayedRows);
    }
  }, [gridApi]);

  useEffect(() => {
    const initialMonthCols = getInitialColumnDefs(
      JSON.parse(JSON.stringify(preprocessedMonthlyData))
    ).map((col, i) => {
      return {
        field: col.field,
        hide: col.hide,
      };
    });
    const initialNonMonthCols = getInitialColumnDefs(
      JSON.parse(JSON.stringify(preprocessedData))
    ).map((col, i) => {
      return {
        field: col.field,
        hide: col.hide,
      };
    });

    const saveInitialSelections = async () => {
      try {
        const columns = await saveColumnSelection({
          idToken: await currentUser.getIdToken(),
          columns: {
            month: initialMonthCols,
            nonMonth: initialNonMonthCols,
          },
        });
      } catch (error) {
        console.log("could not save initial columns", error);
      }
    };

    const fetchColumns = async () => {
      try {
        const columns = await fetchColumnSelection({
          idToken: await currentUser.getIdToken(),
        });
        return columns;
      } catch (error) {
        return false;
      }
    };

    const needToResetCols = (fetchedCols) => {
      if (!fetchedCols) {
        console.log("fetched cols does not exist or is false");
        return true;
      }
      for (let i = 0; i < fetchedCols.month.length; i++) {
        if (
          (Object.keys(fetchedCols.month[i]).length === 2 &&
            Object.keys(fetchedCols.month[i]).includes("field") &&
            Object.keys(fetchedCols.month[i]).includes("hide")) ||
          (Object.keys(fetchedCols.month[i]).length <= 2 &&
            (Object.keys(fetchedCols.month[i]).includes("field") ||
              Object.keys(fetchedCols.month[i]).includes("hide")))
        ) {
          continue;
        } else {
          console.log(
            "I have some columns that have info i do not want",
            fetchedCols.month[i]
          );
          return true;
        }
      }
      for (let i = 0; i < fetchedCols.nonMonth.length; i++) {
        if (
          (Object.keys(fetchedCols.nonMonth[i]).length === 2 &&
            Object.keys(fetchedCols.nonMonth[i]).includes("field") &&
            Object.keys(fetchedCols.nonMonth[i]).includes("hide")) ||
          (Object.keys(fetchedCols.nonMonth[i]).length <= 2 &&
            (Object.keys(fetchedCols.nonMonth[i]).includes("field") ||
              Object.keys(fetchedCols.nonMonth[i]).includes("hide")))
        ) {
          continue;
        } else {
          console.log(
            "I have some columns that have info i do not want for nonMonth"
          );
          return true;
        }
      }
      return false;
    };

    const fullFunction = async () => {
      const fetchedCols = await fetchColumns();
      const needToReset = needToResetCols(fetchedCols);
      console.log("what is need to reset", needToReset);
      if (!fetchedCols || needToReset) {
        await saveInitialSelections();
      } else {
        const fullCols = getInitialColumnDefs(
          JSON.parse(JSON.stringify(preprocessedData))
        );
        const savedColumns = fullCols.map((col) => {
          const foundCol = fetchedCols.nonMonth.find(
            (nonMonthCol) => nonMonthCol.field === col.field
          );
          if (foundCol) {
            return {
              ...col,
              hide: foundCol.hide,
            };
          } else if (col.field === "select" || col.headerName === "View") {
            return {
              ...col,
              hide: false,
            };
          } else {
            return {
              ...col,
              hide: true,
            };
          }
        });
        setColDefs(savedColumns);
        setNonMonthColDefs(fetchedCols.nonMonth);
        setMonthColDefs(fetchedCols.month);
      }
    };

    fullFunction();
  }, []);

  // useEffect(() => {
  //   const fetchColumns = async () => {
  //     try {
  //       const columns = await fetchColumnSelection({
  //         idToken: await currentUser.getIdToken(),
  //       });
  //       return columns;
  //     } catch (error) {
  //       return false;
  //     }
  //   };

  //   const doTheFetch = async () => {
  //     const fetchedCols = await fetchColumns();
  //     console.log("what is fetchedCols", fetchedCols);
  //     const initialColDefs = getInitialColumnDefs(
  //       JSON.parse(JSON.stringify(preprocessedData))
  //     );
  //     if (fetchedCols === false) {
  //       setColDefs(initialColDefs);
  //     } else {
  //       const savedColumns = initialColDefs.map((col) => {
  //         if (fetchedCols.includes(col.field)) {
  //           return {
  //             ...col,
  //             hide: false,
  //           };
  //         } else {
  //           return {
  //             ...col,
  //             hide: true,
  //           };
  //         }
  //       });
  //       setColDefs(savedColumns);
  //     }
  //   };

  //   doTheFetch();
  // }, [EditColumnsModalOpen]);

  useEffect(() => {
    // Trigger the initial update of displayed data and calculation of weighted average
    updateDisplayedData();

    if (gridApi) {
      // Update the average whenever the row data or filter/sort changes
      gridApi.addEventListener("filterChanged", updateDisplayedData);
      gridApi.addEventListener("sortChanged", updateDisplayedData);
    }

    // Cleanup event listeners on unmount
    return () => {
      if (gridApi) {
        gridApi.removeEventListener("filterChanged", updateDisplayedData);
        gridApi.removeEventListener("sortChanged", updateDisplayedData);
      }
    };
  }, [gridApi, updateDisplayedData]);

  useEffect(() => {
    // Recalculate the weighted average whenever displayedData changes
    if (checkForElectric(preprocessedData)) {
      const blendedAvg = calculateAverageBlendedRate(
        displayedData,
        solarProductivity
      );
      const weightedAvg = calculateAverageBlendedRate(
        displayedData,
        solarProductivity,
        true
      );
      setAverageBlendedRate(blendedAvg);
      setWeightedAverageBlendedRate(weightedAvg);
    }
  }, [displayedData]);

  // functions to handle grid and column edits and settings

  const handleChangeToMonthly = (e) => {
    if (e) {
      setMonthly(true);
      setAverageBlendedRate("0 $/kWh");
      setWeightedAverageBlendedRate("0 $/kWh");
      setRowDataState(preprocessedMonthlyData);
      // setColDefs(monthColDefs);
      const fullCols = getInitialColumnDefs(
        JSON.parse(JSON.stringify(preprocessedMonthlyData))
      );
      const savedColumns = fullCols.map((col) => {
        const foundCol = monthColDefs.find(
          (monthCol) => monthCol.field === col.field
        );
        if (foundCol) {
          return {
            ...col,
            hide: foundCol.hide,
          };
        } else if (col.field === "select" || col.headerName === "View") {
          return {
            ...col,
            hide: false,
          };
        } else {
          return {
            ...col,
            hide: true,
          };
        }
      });
      setColDefs(savedColumns);
    } else {
      setMonthly(false);
      const blendedAvg = calculateAverageBlendedRate(
        displayedData,
        solarProductivity
      );
      const weightedAvg = calculateAverageBlendedRate(
        displayedData,
        solarProductivity,
        true
      );
      setAverageBlendedRate(blendedAvg);
      setWeightedAverageBlendedRate(weightedAvg);
      setRowDataState(preprocessedData);
      const fullCols = getInitialColumnDefs(
        JSON.parse(JSON.stringify(preprocessedData))
      );
      const savedColumns = fullCols.map((col) => {
        const foundCol = nonMonthColDefs.find(
          (nonMonthCol) => nonMonthCol.field === col.field
        );
        if (foundCol) {
          return {
            ...col,
            hide: foundCol.hide,
          };
        } else if (col.field === "select" || col.headerName === "View") {
          return {
            ...col,
            hide: false,
          };
        } else {
          return {
            ...col,
            hide: true,
          };
        }
      });
      setColDefs(savedColumns);
      // setColDefs(getInitialColumnDefs(preprocessedData));
    }
  };

  const handleColChange = async (newColDefs) => {
    setColDefs(newColDefs);
  };

  const handleCloseEditColumnsModal = () => {
    setEditColumnsModalOpen(false);
  };

  const handleCloseSaveModal = () => {
    setSaveModalOpen(false);
  };

  const resetData = () => {
    setRowDataState(preprocessedData);
    setIsEditing(false);
  };

  useEffect(() => {
    // Deep copy of preprocessedData each time it changes
    setRowDataState(JSON.parse(JSON.stringify(preprocessedData)));
  }, []);

  const handleCellValueChanged = useCallback((event) => {
    setRowDataState((prevRowDataState) => {
      return prevRowDataState.map((row) => {
        if (row.id === event.data.id) {
          return { ...event.data }; // Ensure new object is returned
        }
        return row;
      });
    });
    setIsEditing(true);
  }, []);

  const handleDelete = useCallback(() => {
    // Remove selected data from rowDataState and update grid
    const newData = rowDataState.filter((data) => !selectedRows.includes(data));
    setRowDataState(newData);
    setIsEditing(true); // Set editing state to true after deleting
  }, [selectedRows, rowDataState]);

  const sortGrid = useCallback((api) => {
    const columnState = {
      state: [
        { colId: "accountNumber", sort: "asc" },
        { colId: "startDate", sort: "desc" },
        { colId: "deliveryDate", sort: "desc" },
      ],
    };
    api.applyColumnState(columnState);
  }, []);

  const onGridReady = useCallback(
    (params) => {
      setGridApi(params.api);
      sortGrid(params.api);
    },
    [sortGrid]
  );

  const onSelectionChanged = useCallback(() => {
    const selectedNodes = gridApi.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node.data);
    setSelectedRows(selectedData);
    console.log("what is selected data", selectedData);
  }, [gridApi]);

  const handleDownloadGrid = () => {
    if (gridApi) {
      const params = {
        fileName: `${name} data.csv`,
        columnSeparator: ",",
      };
      gridApi.exportDataAsCsv(params);
    }
  };

  const handleOpenDownloadModal = async () => {
    setopenDownloadModal(true);
  };

  const handleCloseDownloadModal = () => {
    setopenDownloadModal(false);
  };

  const addRow = () => {
    const currentData = JSON.parse(JSON.stringify(rowDataState));
    currentData.push({});
    setRowDataState(currentData);
  };

  return (
    <div className="ag-theme-quartz" style={{ marginTop: "24px" }}>
      <DownloadButtonContainer>
        <div
          style={{ marginRight: "8px" }}
          onClick={() => {
            setEditColumnsModalOpen(true);
          }}
        >
          <SquareButton width="126px" text="Customize Table" />
        </div>
        <div onClick={handleOpenDownloadModal}>
          <SquareButton width="100px" text="Download" />
        </div>
      </DownloadButtonContainer>
      <GridContainer>
        <style>
          {`
                .ag-theme-quartz .ag-cell-focus {
                    outline: none;
                    border: 0 !important;
                }
                
                .ag-theme-quartz .ag-cell-no-focus .ag-cell-focus {
                    border: none;
                }
                `}
        </style>
        <AgGridReact
          onGridReady={onGridReady}
          rowData={rowDataState}
          columnDefs={colDefs}
          onCellValueChanged={handleCellValueChanged}
          onSelectionChanged={onSelectionChanged}
          getRowStyle={getRowStyle}
          gridOptions={{ autoSizeStrategy: "SizeColumnsToContentStrategy" }}
          rowSelection="multiple"
          stopEditingWhenCellsLoseFocus={true}
        />
      </GridContainer>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "8px",
        }}
      >
        <ButtonContainer>
          <div
            style={monthly ? { display: "none" } : { marginRight: "8px" }}
            onClick={!(selectedRows.length === 0) ? handleDelete : () => {}}
          >
            <SquareButton
              width="100px"
              disabled={selectedRows.length === 0}
              bordercolor="#FA0E0E"
              hovercolor="#ffa192"
              backgroundcolor="#FFF3F3"
              text="Delete Rows"
            />
          </div>
          {/* <div style={monthly ? { display: 'none' } : { marginRight: '8px' }} onClick={addRow}>
                        <SquareButton width="80px" text="Add Row" />
                    </div> */}
          <div
            style={monthly ? { display: "none" } : { marginRight: "8px" }}
            onClick={
              !(!gridApi || (!isEditing && !gridApi.getEditingCells().length))
                ? () => {
                    setSaveModalOpen(true);
                  }
                : () => {}
            }
          >
            <SquareButton
              width="70px"
              disabled={
                !gridApi || (!isEditing && !gridApi.getEditingCells().length)
              }
              text="Save"
            />
          </div>
        </ButtonContainer>
        <AvgsContainer
          style={{ display: averageBlendedRate === "0 $/kWh" ? "none" : "" }}
        >
          <Avg>{"Avg kWh Rate: " + averageBlendedRate}</Avg>
          <Avg>{"Solar Avoided Cost: " + weightedAverageBlendedRate}</Avg>
        </AvgsContainer>
      </div>

      <SaveModal
        open={saveModalOpen}
        handleClose={handleCloseSaveModal}
        saveFunction={saveRowData}
        data={rowDataState}
        resetData={resetData}
      />
      <EditColumnsModal
        open={EditColumnsModalOpen}
        handleClose={handleCloseEditColumnsModal}
        columnDefs={colDefs}
        onColumnToggle={handleColChange}
        onMonthlyToggle={handleChangeToMonthly}
        monthly={monthly}
      />
      <DownloadMeterData
        open={openDownloadModal}
        handleClose={handleCloseDownloadModal}
        data={rawData}
        downloadTableData={handleDownloadGrid}
      />
    </div>
  );
};

export default MeterDataTable;
