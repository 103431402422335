import React, { useState } from "react";
import styled from "styled-components";
import NavBar from "../../../../components/navbar/NavBar";
import { black, blue, blueTheme, grey, white } from "../../../../utils/colors";
import {
  uploadEnergyBillsHeader,
  uploadEnergyBillsSubHeader,
} from "../../../../utils/copy";
import { FileUploader } from "react-drag-drop-files";
import UploadIcon from "../../../../assets/UploadIcon.svg";
import { isNil } from "lodash";
import Document from "../../../../assets/Document.svg";
import Trash from "../../../../assets/Trash.svg";
import { useNavigate } from "react-router-dom";
import FormButton from "../../../../components/buttons/FormButton";
import {
  getPdfFromImages,
  pdfBillReader,
  testPDF,
} from "./apiCalls/pdfBillReader";
import Input from "../../../../components/Input";
import { useGlobalData } from "../../../../store/GlobalDataProvider";
import {
  FormControlLabel,
  Switch,
  ThemeProvider,
  Tooltip,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useSnackbar } from "notistack";
import {
  buttons,
  snackbarStyles,
} from "../../../../components/snackbars/MessageBar";
import LoginWrapper from "../../../../store/LoginWrapper";
import { useAuth } from "../../../../store/AuthContext";
import UpgradeAccountModal from "../../components/UpgradeAccount";

const NavContainer = styled.div`
  display: flex;
  min-width: 1100px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 200px auto;
  //   align-items: center;
  width: 470px;
`;

const Header = styled.div`
  color: ${black};
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const SubHeader = styled.div`
  color: ${black};
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 18px;
`;

const UploadBillsBody = styled.div`
  display: flex;
  gap: 15px;
`;

const IconTextContainer = styled.div`
  margin: 0 auto;
  margin-top: 20px;
  width: 200px;
  //   border-radius: 30px;
  height: 100px;
  //   transition: 0.3s;
  padding-top: 40px;
`;

const DragDropIcon = styled.img`
  margin-left: 85px;
  opacity: 0.2;
  //   transition: 0.3s;
`;

const DragDropText = styled.div`
  font-size: 12px;
  color: ${(props) => (props.error ? "#FB2828" : "#9F9F9F")};
  width: 100%;
  line-height: 1.5;
  text-align: center;
  margin-top: 5px;
  //   transition: 0.3s;
`;

const DragDropArea = styled.div`
  width: 231px;
  height: 178px;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
  //   transition: 0.3s;
  background-color: ${white};
  &:hover {
    // background-color: #eff2ff;
    // background-color: #dbeafe;
    background-color: #f3f6ff;
  }
  &:hover ${DragDropText} {
    ${(props) => (props.error ? "" : "color:" + blue)}
  }
  &:hover ${DragDropIcon} {
    opacity: 1;
    filter: brightness(0) saturate(100%) invert(31%) sepia(23%) saturate(5347%)
      hue-rotate(218deg) brightness(103%) contrast(113%);
  }
  &:hover ${IconTextContainer} {
    // background-color: #f3f6ff;
    // background-color: #dbeafe;
    background-color: #f3f6ff;
  }
`;

const UploadedBillsContainer = styled.div`
  border-radius: 5px;
  border: 1px solid ${grey};
  padding: 16px;
  height: 178px;
  width: 231px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: scroll;
  background-color: ${white};
`;

const FileItem = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  justify-content: space-between;
`;

const FileIcon = styled.img`
  height: 16px;
  width: 16px;
`;

const FileName = styled.div`
  color: ${black};
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
`;

const Actions = styled.div`
  display: flex;
  justify-content: end;
  gap: 30px;
  align-items: center;
  margin-top: 10px;
`;

const SecondaryAction = styled.div`
  //   padding-bottom: 1px;
  padding: 0 3px;
  color: ${blue};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  cursor: pointer;
  &:hover {
    border-bottom: 1px solid ${blue};
  }
`;

const TrashIcon = styled.img`
  height: 16px;
  width: 16px;
  cursor: pointer;
  &:hover {
    filter: brightness(0) saturate(100%) invert(35%) sepia(23%) saturate(4347%)
      hue-rotate(0deg) brightness(103%) contrast(113%);
  }
`;

const PageContainer = styled.div`
  width: 100%;
  height: 100vh;
  // background-color: #eff6ff;
  // background-color: #ffffff;
  background-color: #fdfdfd;
`;

const Flex = styled.div`
  display: flex;
`;

const fileTypes = ["jpg", "jpeg", "png", "pdf", "tiff"];
const imagesOnlyFileTypes = ["jpg", "jpeg", "png", "tiff"];

const UploadEnergyBills = ({ withNav = true }) => {
  const { currentUser } = useAuth();
  const [file, setFile] = useState(null);
  const [typeError, setTypeError] = useState(false);
  const navigate = useNavigate();
  const [isUploading, setIsUploading] = useState(false);
  const [nameOfCustomer, setNameOfCustomer] = useState(null);
  const [detailed, setDetailed] = useState(true);
  const [imagesOnly, setImagesOnly] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [upgradeAccountModalOpen, setUpgradeAccountModalOpen] = useState(false);
  const { userData } = useGlobalData();

  const handleCloseUpgradeAccountModal = () => {
    setUpgradeAccountModalOpen(false);
  };

  // const handleDetailedChange = () => {
  //   detailed ? setDetailed(false) : setDetailed(true);
  // };

  const handleImagesOnlyChange = () => {
    imagesOnly ? setImagesOnly(false) : setImagesOnly(true);
  };

  const handleNameChange = (value) => {
    setNameOfCustomer(value);
  };

  const handleChange = (f) => {
    function filterFilesBySize(fileList) {
      const maxSize = 15 * 1024 * 1024; // 15MB in bytes
      const filteredFiles = [];

      for (let i = 0; i < fileList.length; i++) {
        const file = fileList[i];
        if (file.size <= maxSize) {
          filteredFiles.push(file);
        }
      }

      return filteredFiles;
    }

    if (file === null) {
      setFile(filterFilesBySize(f));
    } else if (f === null) {
      setFile(null);
    } else {
      const joined = Array.from(file).concat(Array.from(filterFilesBySize(f)));
      setFile(joined);
    }

    if (filterFilesBySize(f).length === f.length) {
      setTypeError(false);
    } else {
      setTypeError(true);
    }
  };

  const getText = (error, files) => {
    if (error) {
      if (imagesOnly) {
        return "Unsupported file type - JPEG or PNG under 15MB only";
      }
      return "Unsupported file type - PDF, JPEG, or PNG under 15MB only";
    } else if (files === null) {
      return "Upload or drop bills here";
    } else if (files.length === 1) {
      return "1 file ready";
    } else {
      return `${files.length} files ready`;
    }
  };

  const handleUploadBills = async () => {
    if (imagesOnly) {
      try {
        const pdf = await getPdfFromImages({
          files: file,
          idToken: await currentUser.getIdToken(),
        });

        // const blob = new Blob([pdf.data], { type: "application/pdf" });

        // // Create a URL for the Blob
        // const blobUrl = URL.createObjectURL(blob);

        // // Create a link element to trigger the download
        // const downloadLink = document.createElement("a");
        // downloadLink.href = blobUrl;
        // downloadLink.download = "abc.pdf"; // Name of the downloaded file
        // downloadLink.click();

        // // Clean up the Blob URL after the download
        // URL.revokeObjectURL(blobUrl);

        // const linkSource = `data:application/pdf;base64,${pdf.data}`;
        // const downloadLink = document.createElement("a");
        // const fileName = "abc.pdf";
        // downloadLink.href = linkSource;
        // downloadLink.download = fileName;
        // downloadLink.click();

        const pdfBlob = new Blob([pdf.data], { type: "application/pdf" });
        // Create a File object to mimic an uploaded file (if necessary)
        const pdfFile = new File([pdfBlob], "generated.pdf", {
          type: "application/pdf",
        });

        // Pass the File object to the `pdfBillReader` function
        const files = { 0: pdfFile }; // Mimic the structure of a file input

        if (
          userData &&
          userData.payments.subscription.plan !== "payAsYouGo" &&
          userData.payments.subscription.remainingBills < files.length
        ) {
          setUpgradeAccountModalOpen(true);
        } else if (!isUploading) {
          setIsUploading(true);
          try {
            await pdfBillReader({
              files: files,
              customer: nameOfCustomer,
              idToken: await currentUser.getIdToken(),
              detailed: detailed,
              environment: "production",
            });
            navigate("/requests");
            enqueueSnackbar(buttons.success, { style: snackbarStyles.success });
          } catch (error) {
            enqueueSnackbar(buttons.error, { style: snackbarStyles.error });
            setIsUploading(false);
            console.log("error uploading bills", error);
          }
        }
      } catch (error) {
        console.error("what is error", error);
      }
    } else {
      if (
        userData &&
        userData.payments.subscription.plan !== "payAsYouGo" &&
        userData.payments.subscription.remainingBills < file.length
      ) {
        setUpgradeAccountModalOpen(true);
      } else if (!isUploading) {
        setIsUploading(true);
        try {
          await pdfBillReader({
            files: file,
            customer: nameOfCustomer,
            idToken: await currentUser.getIdToken(),
            detailed: detailed,
            environment: "production",
          });
          navigate("/requests");
          enqueueSnackbar(buttons.success, { style: snackbarStyles.success });
        } catch (error) {
          enqueueSnackbar(buttons.error, { style: snackbarStyles.error });
          setIsUploading(false);
          console.log("error uploading bills", error);
        }
      }
    }
  };

  const onClickTrash = (e, index) => {
    var newFiles = [...file];
    newFiles.splice(index, 1);
    setFile(newFiles);
  };

  const tooltipText =
    "Toggle to enable detailed data extraction. Detailed extraction extracts more data from each energy bill, but requires more processing time.";

  const imagesOnlyText =
    "If you are uploading bills that have been captured as pictures, please select this option. Note: when this option is selected only images will be allowed in the upload section.";

  return (
    <LoginWrapper>
      {withNav && (
        <NavContainer>
          <NavBar currentPage={"upload-energy-bills"} />
          <PageContainer>
            <Container>
              <Header>{uploadEnergyBillsHeader}</Header>
              <SubHeader>{uploadEnergyBillsSubHeader}</SubHeader>
              <Input
                title={"Name"}
                value={nameOfCustomer}
                setChangeInParent={handleNameChange}
              />
              <Flex>
                <FormControlLabel
                  value="start"
                  control={
                    <ThemeProvider theme={blueTheme}>
                      <Switch
                        color="primary"
                        onChange={handleImagesOnlyChange}
                      />
                    </ThemeProvider>
                  }
                  label="Bills as pictures"
                />
                <div style={{ marginLeft: "-10px", marginTop: "9px" }}>
                  <Tooltip title={imagesOnlyText}>
                    <InfoOutlinedIcon fontSize="small" />
                  </Tooltip>
                </div>
              </Flex>

              <UploadBillsBody>
                <FileUploader
                  multiple={true}
                  handleChange={handleChange}
                  name="file"
                  types={imagesOnly ? imagesOnlyFileTypes : fileTypes}
                  onTypeError={(err) => {
                    setTypeError(true);
                  }}
                  fileOrFiles={"/TestBill.pdf"}
                  children={
                    <DragDropArea error={typeError}>
                      <IconTextContainer>
                        <DragDropIcon src={UploadIcon} />
                        <DragDropText error={typeError}>
                          {getText(typeError, file)}
                        </DragDropText>
                      </IconTextContainer>
                    </DragDropArea>
                  }
                />
                <UploadedBillsContainer>
                  {!isNil(file) &&
                    Array.isArray(file) &&
                    file.map((f, i) => {
                      return (
                        <FileItem>
                          <div style={{ display: "flex", gap: "5px" }}>
                            <FileIcon src={Document} />
                            <FileName>{f.name}</FileName>
                          </div>

                          <TrashIcon
                            src={Trash}
                            title={"Delete"}
                            onClick={(e) => onClickTrash(e, i)}
                          />
                        </FileItem>
                      );
                    })}
                  {!isNil(file) &&
                    !Array.isArray(file) &&
                    Object.entries(file).map((f, i) => {
                      return (
                        <FileItem>
                          <div style={{ display: "flex", gap: "5px" }}>
                            <FileIcon src={Document} />
                            <FileName>{f[1].name}</FileName>
                          </div>

                          <TrashIcon
                            src={Trash}
                            title={"Delete"}
                            onClick={(e) => onClickTrash(e, i)}
                          />
                        </FileItem>
                      );
                    })}
                </UploadedBillsContainer>
              </UploadBillsBody>
              <Actions>
                <SecondaryAction onClick={() => navigate(-1)}>
                  Back
                </SecondaryAction>
                <FormButton
                  content={"Upload"}
                  height={"28px"}
                  width={"93px"}
                  disabled={
                    file && !isNil(nameOfCustomer) && nameOfCustomer !== ""
                      ? false
                      : true
                  }
                  isloading={isUploading}
                  handleClick={handleUploadBills}
                />
              </Actions>
            </Container>
          </PageContainer>
        </NavContainer>
      )}
      {!withNav && (
        <PageContainer>
          <Container>
            <Header>{uploadEnergyBillsHeader}</Header>
            <SubHeader>{uploadEnergyBillsSubHeader}</SubHeader>
            <Input
              title={"Name"}
              value={nameOfCustomer}
              setChangeInParent={handleNameChange}
            />
            <UploadBillsBody>
              <FileUploader
                multiple={true}
                handleChange={handleChange}
                name="file"
                types={fileTypes}
                onTypeError={(err) => {
                  setTypeError(true);
                }}
                fileOrFiles={"/TestBill.pdf"}
                children={
                  <DragDropArea error={typeError}>
                    <IconTextContainer>
                      <DragDropIcon src={UploadIcon} />
                      <DragDropText error={typeError}>
                        {getText(typeError, file)}
                      </DragDropText>
                    </IconTextContainer>
                  </DragDropArea>
                }
              />
              <UploadedBillsContainer>
                {!isNil(file) &&
                  Array.isArray(file) &&
                  file.map((f, i) => {
                    return (
                      <FileItem>
                        <div style={{ display: "flex", gap: "5px" }}>
                          <FileIcon src={Document} />
                          <FileName>{f.name}</FileName>
                        </div>

                        <TrashIcon
                          src={Trash}
                          title={"Delete"}
                          onClick={(e) => onClickTrash(e, i)}
                        />
                      </FileItem>
                    );
                  })}
                {!isNil(file) &&
                  !Array.isArray(file) &&
                  Object.entries(file).map((f, i) => {
                    return (
                      <FileItem>
                        <div style={{ display: "flex", gap: "5px" }}>
                          <FileIcon src={Document} />
                          <FileName>{f[1].name}</FileName>
                        </div>

                        <TrashIcon
                          src={Trash}
                          title={"Delete"}
                          onClick={(e) => onClickTrash(e, i)}
                        />
                      </FileItem>
                    );
                  })}
              </UploadedBillsContainer>
            </UploadBillsBody>
            <Actions>
              <SecondaryAction onClick={() => navigate(-1)}>
                Back
              </SecondaryAction>
              <FormButton
                content={"Upload"}
                height={"28px"}
                width={"93px"}
                disabled={
                  file && !isNil(nameOfCustomer) && nameOfCustomer !== ""
                    ? false
                    : true
                }
                isloading={isUploading}
                handleClick={handleUploadBills}
              />
            </Actions>
          </Container>
        </PageContainer>
      )}
      <UpgradeAccountModal
        upload={true}
        open={upgradeAccountModalOpen}
        handleClose={handleCloseUpgradeAccountModal}
      />
    </LoginWrapper>
  );
};

export default UploadEnergyBills;
