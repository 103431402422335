import ViewPDF from "./viewPDF";

function checkForKey(arr, key) {
  for (let obj of arr) {
    if (key in obj) {
      return true;
    }
  }
  return false;
}

function usageValueFormatter(params) {
  if (params.value) {
    return `${Math.round(params.value)} ${params.data.unit}`;
  }
}

function demandFormatter(params) {
  if (params.value) {
    return `${params.value.toFixed(2)} kW`;
  }
}

function kvarhFormatter(params) {
  if (params.value) {
    return `${params.value.toFixed(2)} kVArh`;
  }
}

function percentFormatter(params) {
  if (params.value) {
    return params.value > 1
      ? `${params.value} %`
      : `${(params.value * 100).toFixed(2)} %`;
  }
}

function dollarFormatter(params) {
  if (params.value) {
    return `$ ${params.value.toFixed(2)}`;
  }
}

function dollarFormatterToFour(params) {
  if (params.value) {
    return `$ ${params.value.toFixed(4)}`;
  }
}

function rateFormatter(params) {
  if (params.value) {
    return `$ ${params.value.toFixed(4)} $/${params.data.unit}`;
  }
}

function convertDateFormat(params) {
  if (params.value) {
    // Split the input date string by hyphen
    const [year, month, day] = params.value.split("-");

    // Return the date in MM/DD/YYYY format
    return `${month}/${day}/${year}`;
  }
}

const formatMonthDate = (params) => {
  if (params.value) {
    const [year, month] = params.value.split("-").map(Number);

    const date = new Date(year, month - 1); // Create a date object (JavaScript months are 0-indexed)

    const monthName = date.toLocaleString("default", { month: "long" });

    return `${monthName} ${year}`;
  }
};

const sortMonths = (valueA, valueB, nodeA, nodeB, isDescending) => {
  // Convert date from YYYY-M to a comparable value
  const parseDate = (dateStr) => {
    const [year, month] = dateStr.split("-").map(Number);
    return new Date(year, month - 1); // JavaScript months are zero-indexed
  };

  const date1Parsed = parseDate(valueA);
  const date2Parsed = parseDate(valueB);

  if (date1Parsed < date2Parsed) return -1;
  if (date1Parsed > date2Parsed) return 1;
  return 0;
};

function daysBetweenDates(date1, date2) {
  // Parse the dates using the Date constructor
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  // Calculate the time difference in milliseconds
  const timeDiff = Math.abs(d2 - d1);

  // Convert the time difference from milliseconds to days
  const diffDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

  return diffDays;
}

function handleDivideByZero(a, b) {
  if (!!a && !!b) return a / b;
  return null;
}

function ifNil(a) {
  return !a ? 0 : a;
}

export function preprocessData(arr, fileMap) {
  var result = arr.map((item, index) => {
    item.id = index;
    if ("startDate" in item && "endDate" in item) {
      item.numberOfDays = daysBetweenDates(item.startDate, item.endDate);

      if ("energySupplyCharges" in item || "energyDeliveryCharges" in item) {
        item.energySupplyRate = handleDivideByZero(
          item.energySupplyCharges,
          item.usage
        );
        item.energyDeliveryRate = handleDivideByZero(
          item.energyDeliveryCharges,
          item.usage
        );
        item.totalSupplyRate = handleDivideByZero(
          item.totalSupplyCharges,
          item.usage
        );
        item.totalDeliveryRate = handleDivideByZero(
          item.totalDeliveryCharges,
          item.usage
        );

        if (
          [
            "supplySalesTax",
            "supplyOtherTax",
            "deliverySalesTax",
            "deliveryOtherTax",
          ].every((key) => key in item)
        ) {
          item.totalEnergyChargesWithTax =
            ifNil(item.energySupplyCharge) *
              (1 + ifNil(item.supplyOtherTax)) *
              (1 + ifNil(item.supplySalesTax)) +
            ifNil(item.energyDeliveryCharges) *
              (1 + ifNil(item.deliveryOtherTax)) *
              (1 + ifNil(item.deliverySalesTax));

          item.totalDemandChargesWithTax =
            ifNil(item.demandSupplyCharges) *
              (1 + ifNil(item.supplyOtherTax)) *
              (1 + ifNil(item.supplySalesTax)) +
            ifNil(item.demandDeliveryCharges) *
              (1 + ifNil(item.deliveryOtherTax)) *
              (1 + ifNil(item.deliverySalesTax));

          item.blendedRate = item.totalEnergyChargesWithTax / item.usage;
          item.energySupplyRate = item.energySupplyCharges / item.usage;
          item.energyDeliveryRate = item.energyDeliveryCharges / item.usage;
        }

        if (
          "supplyTaxes" in item ||
          "deliveryTaxes" in item ||
          "additionalTaxes" in item
        ) {
          if (Object.keys(item).includes("additionalEnergyCharges")) {
            item.totalEnergyChargesWithTax =
              ifNil(item.energySupplyCharges) * (1 + ifNil(item.supplyTaxes)) +
              ifNil(item.energyDeliveryCharges) *
                (1 + ifNil(item.deliveryTaxes)) +
              ifNil(item.additionalEnergyCharges) *
                (1 + ifNil(item.additionalTaxes));
          } else {
            item.totalEnergyChargesWithTax =
              (ifNil(item.energySupplyCharges) * (1 + ifNil(item.supplyTaxes)) +
                ifNil(item.energyDeliveryCharges) *
                  (1 + ifNil(item.deliveryTaxes))) *
              (1 + ifNil(item.additionalTaxes));
          }

          item.totalDemandChargesWithTax =
            (ifNil(item.demandSupplyCharges) * (1 + ifNil(item.supplyTaxes)) +
              ifNil(item.demandDeliveryCharges) *
                (1 + ifNil(item.deliveryTaxes))) *
            (1 + ifNil(item.additionalTaxes));

          item.blendedRate = item.totalEnergyChargesWithTax / item.usage;
        } else {
          console.log(item);
        }
      }
    }

    if ("filename" in item) {
      var fileLocation = fileMap[item.filename];
      item.location = fileLocation;
    }

    return item;
  });

  console.log("test", 1 + null);
  console.log(result);
  return result;
}

export const getInitialColumnDefs = (arr) => {
  return [
    !checkForKey(arr, "month") && {
      field: "select",
      headerName: "",
      checkboxSelection: true,
      width: 40,
      cellClass: "no-border",
      hide: false,
    },
    {
      field: "accountNumber",
      header: "Account Number",
      filter: "agTextColumnFilter",
      editable: !checkForKey(arr, "month"),
      hide: false,
      valueFormatter: (params) => {
        if (params.value) {
          return String(params.value);
        }
      },
    },
    checkForKey(arr, "procurementId") && {
      field: "procurementId",
      header: "Procurement ID",
      filter: "agTextColumnFilter",
      editable: !checkForKey(arr, "month"),
      hide: true,
      valueFormatter: (params) => {
        if (params.value) {
          return String(params.value);
        }
      },
    },
    checkForKey(arr, "meterNumbers") && {
      field: "meterNumbers",
      header: "Meter Numbers",
      filter: "agTextColumnFilter",
      editable: false,
      hide: false,
      valueFormatter: (params) => {
        if (params.value) {
          return params.value.join(", ");
        }
      },
    },
    checkForKey(arr, "month") && {
      field: "month",
      header: "Month",
      filter: "agTextColumnFilter",
      editable: false,
      hide: false,
      pinned: "left",
      comparator: sortMonths,
      valueFormatter: formatMonthDate,
      width: 160,
    },
    {
      field: "serviceClass",
      header: "Service Class",
      filter: "agTextColumnFilter",
    },
    {
      field: "utility",
      header: "Utility",
      filter: "agTextColumnFilter",
      editable: !checkForKey(arr, "month"),
      hide: false,
    },
    checkForKey(arr, "supplier") && {
      field: "supplier",
      header: "Supplier",
      filter: "agTextColumnFilter",
      editable: !checkForKey(arr, "month"),
      hide: true,
    },
    checkForKey(arr, "invoicer") && {
      field: "invoicer",
      header: "Invoicer",
      filter: "agTextColumnFilter",
      editable: !checkForKey(arr, "month"),
      hide: true,
    },
    {
      field: "confidence",
      header: "Confidence",
      filter: "agNumberColumnFilter",
      hide: true,
    },
    checkForKey(arr, "serviceReference") && {
      field: "serviceReference",
      header: "Service Reference",
      filter: "agTextColumnFilter",
      editable: !checkForKey(arr, "month"),
      hide: false,
    },
    {
      field: "serviceAddress",
      header: "Service Address",
      filter: "agTextColumnFilter",
      editable: !checkForKey(arr, "month"),
      hide: false,
    },
    {
      field: "customer",
      header: "Customer",
      filter: "agTextColumnFilter",
      editable: !checkForKey(arr, "month"),
      hide: false,
    },
    {
      field: "tariff",
      header: "Tariff",
      filter: "agNumberColumnFilter",
      editable: !checkForKey(arr, "month"),
      hide: false,
    },
    checkForKey(arr, "startDate") && {
      field: "startDate",
      header: "Start Date",
      filter: "agDateColumnFilter",
      valueFormatter: convertDateFormat,
      editable: !checkForKey(arr, "month"),
      hide: false,
    },
    checkForKey(arr, "endDate") && {
      field: "endDate",
      header: "End Date",
      filter: "agDateColumnFilter",
      valueFormatter: convertDateFormat,
      editable: !checkForKey(arr, "month"),
      hide: false,
    },
    checkForKey(arr, "numberOfDays") && {
      field: "numberOfDays",
      header: "Number of Days",
      filter: "agNumberColumnFilter",
      editable: false,
      hide: true,
    },
    checkForKey(arr, "deliveryDate") && {
      field: "deliveryDate",
      header: "Delivery Date",
      filter: "agDateColumnFilter",
      valueFormatter: convertDateFormat,
      editable: !checkForKey(arr, "month"),
      hide: false,
    },
    {
      field: "lastYearTotalUsage",
      header: "Last Year Total Usage",
      filter: "agNumberColumnFilter",
      editable: !checkForKey(arr, "month"),
      hide: false,
      useValueFormatterForExport: false,
    },
    {
      field: "usage",
      header: "Usage",
      filter: "agNumberColumnFilter",
      editable: !checkForKey(arr, "month"),
      hide: false,
      useValueFormatterForExport: false,
    },
    {
      field: "unit",
      header: "Unit",
      filter: "agNumberColumnFilter",
      editable: !checkForKey(arr, "month"),
      hide: false,
      useValueFormatterForExport: false,
    },
    checkForKey(arr, "demand") && {
      field: "demand",
      header: "Demand",
      filter: "agNumberColumnFilter",
      valueFormatter: demandFormatter,
      editable: !checkForKey(arr, "month"),
      hide: false,
      useValueFormatterForExport: false,
    },
    {
      field: "totalCharges",
      header: "Total Charges",
      filter: "agNumberColumnFilter",
      valueFormatter: dollarFormatter,
      editable: !checkForKey(arr, "month"),
      hide: false,
    },
    // deprecated tax key
    checkForKey(arr, "supplySalesTax") && {
      field: "supplySalesTax",
      header: "Supply Sales Tax Rate",
      filter: "agNumberColumnFilter",
      valueFormatter: percentFormatter,
      editable: !checkForKey(arr, "month"),
      hide: true,
    },
    // deprecated tax key
    checkForKey(arr, "deliverySalesTax") && {
      field: "deliverySalesTax",
      header: "Delivery Sales Tax Rate",
      filter: "agNumberColumnFilter",
      valueFormatter: percentFormatter,
      editable: !checkForKey(arr, "month"),
      hide: true,
    },
    // deprecated tax key
    checkForKey(arr, "supplyOtherTax") && {
      field: "supplyOtherTax",
      header: "Supply Other Taxes Rate",
      filter: "agNumberColumnFilter",
      valueFormatter: percentFormatter,
      editable: !checkForKey(arr, "month"),
      hide: true,
    },
    checkForKey(arr, "supplyTaxes") && {
      field: "supplyTaxes",
      header: "Supply Taxes",
      filter: "agNumberColumnFilter",
      valueFormatter: percentFormatter,
      editable: !checkForKey(arr, "month"),
      hide: true,
    },
    // deprecated tax key
    checkForKey(arr, "deliveryOtherTax") && {
      field: "deliveryOtherTax",
      header: "Delivery Other Taxes Rate",
      filter: "agNumberColumnFilter",
      valueFormatter: percentFormatter,
      editable: !checkForKey(arr, "month"),
      hide: true,
    },
    checkForKey(arr, "deliveryTaxes") && {
      field: "deliveryTaxes",
      header: "Delivery Taxes",
      filter: "agNumberColumnFilter",
      valueFormatter: percentFormatter,
      editable: !checkForKey(arr, "month"),
      hide: true,
    },
    checkForKey(arr, "additionalTaxes") && {
      field: "additionalTaxes",
      header: "Additional Taxes",
      filter: "agNumberColumnFilter",
      valueFormatter: percentFormatter,
      editable: !checkForKey(arr, "month"),
      hide: true,
    },
    checkForKey(arr, "totalDeliveryCharges") && {
      field: "totalDeliveryCharges",
      header: "Total Delivery Charges",
      filter: "agNumberColumnFilter",
      valueFormatter: dollarFormatter,
      editable: !checkForKey(arr, "month"),
      hide: false,
    },
    checkForKey(arr, "totalDeliveryRate") && {
      field: "totalDeliveryRate",
      header: "Total Delivery Rate",
      filter: "agNumberColumnFilter",
      valueFormatter: rateFormatter,
      editable: false,
      hide: true,
    },
    checkForKey(arr, "totalSupplyCharges") && {
      field: "totalSupplyCharges",
      header: "Total Supply Charges",
      filter: "agNumberColumnFilter",
      valueFormatter: dollarFormatter,
      editable: !checkForKey(arr, "month"),
      hide: false,
    },
    checkForKey(arr, "totalSupplyRate") && {
      field: "totalSupplyRate",
      header: "Total Supply Rate",
      filter: "agNumberColumnFilter",
      valueFormatter: rateFormatter,
      editable: false,
      hide: true,
    },
    checkForKey(arr, "demandSupplyCharges") && {
      field: "demandSupplyCharges",
      header: "Demand Supply Charges",
      filter: "agNumberColumnFilter",
      valueFormatter: dollarFormatter,
      editable: !checkForKey(arr, "month"),
      hide: true,
    },
    checkForKey(arr, "demandDeliveryCharges") && {
      field: "demandDeliveryCharges",
      header: "Demand Delivery Charges",
      filter: "agNumberColumnFilter",
      valueFormatter: dollarFormatter,
      editable: !checkForKey(arr, "month"),
      hide: true,
    },
    checkForKey(arr, "totalDemandCharges") && {
      field: "totalDemandCharges",
      header: "Total Demand Charges",
      filter: "agNumberColumnFilter",
      valueFormatter: dollarFormatter,
      editable: !checkForKey(arr, "month"),
      hide: false,
    },
    checkForKey(arr, "totalDemandChargesWithTax") && {
      field: "totalDemandChargesWithTax",
      header: "Total Demand Charges with Tax",
      filter: "agNumberColumnFilter",
      valueFormatter: dollarFormatter,
      editable: !checkForKey(arr, "month"),
      hide: true,
    },
    checkForKey(arr, "energySupplyCharges") && {
      field: "energySupplyCharges",
      header: "Energy Supply Charges",
      filter: "agNumberColumnFilter",
      valueFormatter: dollarFormatter,
      editable: !checkForKey(arr, "month"),
      hide: true,
    },
    checkForKey(arr, "energySupplyRate") && {
      field: "energySupplyRate",
      header: "Energy Supply Rate",
      filter: "agNumberColumnFilter",
      valueFormatter: rateFormatter,
      editable: false,
      hide: true,
    },
    checkForKey(arr, "energyDeliveryCharges") && {
      field: "energyDeliveryCharges",
      header: "Energy Delivery Charges",
      filter: "agNumberColumnFilter",
      valueFormatter: dollarFormatter,
      editable: !checkForKey(arr, "month"),
      hide: true,
    },
    checkForKey(arr, "energyDeliveryRate") && {
      field: "energyDeliveryRate",
      header: "Energy Delivery Rate",
      filter: "agNumberColumnFilter",
      valueFormatter: rateFormatter,
      editable: false,
      hide: true,
    },
    checkForKey(arr, "totalEnergyCharges") && {
      field: "totalEnergyCharges",
      header: "Total Energy Charges",
      filter: "agNumberColumnFilter",
      valueFormatter: dollarFormatter,
      editable: false,
      hide: true,
    },
    checkForKey(arr, "totalEnergyChargesWithTax") && {
      field: "totalEnergyChargesWithTax",
      header: "Total Energy Charges with Tax",
      filter: "agNumberColumnFilter",
      valueFormatter: dollarFormatter,
      editable: false,
      hide: true,
    },
    checkForKey(arr, "blendedRate") && {
      field: "blendedRate",
      header: "Blended Rate",
      filter: "agNumberColumnFilter",
      valueFormatter: dollarFormatterToFour,
      editable: false,
      hide: true,
    },
    checkForKey(arr, "powerFactor") && {
      field: "powerFactor",
      header: "Power Factor",
      filter: "agNumberColumnFilter",
      valueFormatter: percentFormatter,
      editable: !checkForKey(arr, "month"),
      hide: true,
    },
    checkForKey(arr, "reactiveEnergy") && {
      field: "reactiveEnergy",
      header: "Reactive Energy",
      filter: "agNumberColumnFilter",
      valueFormatter: kvarhFormatter,
      editable: !checkForKey(arr, "month"),
      hide: true,
      useValueFormatterForExport: false,
    },
    !checkForKey(arr, "month") && {
      field: null,
      headerName: "View",
      sortable: false,
      suppressNavigable: true,
      width: 90,
      cellRenderer: (params) => <ViewPDF {...params} />,
    },
  ].filter(Boolean);
};
