import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Tabs from "../../components/Tabs";
import { isNil } from "lodash";
import BackButton from "../../components/buttons/BackButton";
import MeterDataOverview from "./pages/meterDataOverview/meterDataOverview";
import MeterDataTable from "./pages/meterDataTable";
import LoadingState from "./pages/loadingState";
import { getRequestData } from "./apiCalls/getRequestData";
import WarningNote from "../../components/notes/WarningNote";
import LoginWrapper from "../../store/LoginWrapper";
import { useAuth } from "../../store/AuthContext";
import { updateRequestBillData } from "./apiCalls/updateRequestBillData";
import { useNavigate, useParams } from "react-router-dom";
import { preprocessData } from "./pages/components/initialColumnDefs";
import { convertToMonthlyData } from "./pages/components/convertDataToMonthly";
import { useGlobalData } from "../../store/GlobalDataProvider";
import { defaultSolarProductivity } from "../../utils/defaultSolarProductivity";
import Page from "../../components/page";

//Testing pushing latest code to heroku

const Container = styled.div`
  width: 100%;
  min-width: 900px;
  margin: 0 auto;
`;

const Heading = styled.div`
  font-size: 24px;
  margin-top: -8px;
  margin-left: 16px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BillsUploadResults = () => {
  const { requestId } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [data, setData] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [name, setName] = useState(null);
  const [preprocessedData, setPreprocessedData] = useState(null);
  const [preprocessedMonthlyData, setPreprocessedMonthlyData] = useState(null);
  const [sharedFilteredRows, setSharedFilteredRows] = useState([]);
  const { userData } = useGlobalData();

  const handleTabChange = (target) => {
    setCurrentTab(target);
  };

  function cleanRows(data) {
    var cleanedRows = [];
    for (let account in data) {
      if (!Array.isArray(data[account])) {
        for (let energy in data[account]) {
          console.log("what is energy", energy);
          data[account][energy].data.forEach((item) => {
            cleanedRows.push({ ...data[account][energy].overview, ...item });
          });
        }
      }
    }
    return cleanedRows;
  }

  async function getDataFromRequestId(requestId) {
    if (requestId) {
      const dataFromRequestId = await getRequestData(
        requestId,
        await currentUser.getIdToken()
      );
      setData(dataFromRequestId.data.data);
      setName(dataFromRequestId.data.data.customer);

      const cleanedRows = cleanRows(dataFromRequestId.data.data.billsData.data);
      console.log("what is cleaned rows", cleanedRows);
      const processedCleaned = preprocessData(
        cleanedRows,
        dataFromRequestId.data.data.fileMap
      );
      setPreprocessedData(processedCleaned);
      setPreprocessedMonthlyData(convertToMonthlyData(processedCleaned));
    }
  }

  async function saveUpdatedRowData(rowData) {
    setData(null);
    await updateRequestBillData(
      rowData,
      requestId,
      await currentUser.getIdToken()
    );
    await getDataFromRequestId(requestId);
    setCurrentTab(0);
  }

  useEffect(() => {
    getDataFromRequestId(requestId);
  }, []);

  const handleBack = () => {
    navigate("/requests");
  };

  return (
    <LoginWrapper>
      <Page currentPage={"requests"}>
        <Container>
          {data != null ? (
            <>
              <HeaderContainer>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <BackButton action={handleBack} />
                    {!isNil(name) && <Heading>{name}</Heading>}
                    {isNil(name) && <Heading>Review Data</Heading>}
                  </div>
                  <div style={{ display: "flex", marginTop: -6 }}>
                    {data === null ? (
                      <></>
                    ) : (
                      <WarningNote
                        data={data.billsData.data}
                        unsupported={data.billsData.unsupportedBills}
                      />
                    )}
                  </div>
                </div>
              </HeaderContainer>
              <div style={{ marginTop: 16 }}>
                <Tabs
                  tabs={["Overview", "Meter Data"]}
                  setTabInParent={handleTabChange}
                />
              </div>
            </>
          ) : (
            <></>
          )}
          {data === null || userData === null ? <LoadingState /> : <></>}
          {currentTab === 0 && data != null && userData != null ? (
            <MeterDataOverview
              solarProductivity={
                userData.userData.solarProductivity ?? defaultSolarProductivity
              }
              data={data.billsData.data}
              preprocessedData={preprocessedData}
              preprocessedMonthlyData={preprocessedMonthlyData}
            />
          ) : (
            <></>
          )}
          {currentTab === 1 && data != null && userData != null ? (
            <div>
              <MeterDataTable
                solarProductivity={
                  userData.userData.solarProductivity ??
                  defaultSolarProductivity
                }
                rawData={data}
                preprocessedData={preprocessedData}
                preprocessedMonthlyData={preprocessedMonthlyData}
                name={name}
                saveRowData={saveUpdatedRowData}
                saveFilteredTableRows={setSharedFilteredRows}
              />
              <MeterDataOverview
                solarProductivity={
                  userData.userData.solarProductivity ??
                  defaultSolarProductivity
                }
                data={data.billsData.data}
                preprocessedData={
                  sharedFilteredRows.length > 0
                    ? sharedFilteredRows
                    : preprocessedData
                }
                preprocessedMonthlyData={preprocessedMonthlyData}
                showAllAccounts={false}
                onOverview={false}
              />
            </div>
          ) : (
            <></>
          )}
        </Container>
      </Page>
    </LoginWrapper>
  );
};

export default BillsUploadResults;
