import React, { useState } from "react";
import Note from "./Note";
import { dark_orange, light_orange } from "../../utils/colors";
import styled from "styled-components";
import Hazard from "../../assets/Hazard.svg";
import { isNil } from "lodash";
import { Popover } from "@mui/material";
import HazardButton from "../buttons/hazardButton";

const UnorderedList = styled.ul`
  margin-bottom: 0px;
  padding-left: 32px;
  color: #000;
`;

const NoteHeading = styled.div`
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
`;

const NoteText = styled.div`
  font-size: 12px;
`;

const NoteIcon = styled.img`
  width: 12px;
  height: 12px;
  margin-right: 8px;
`;

const ListItem = styled.li`
  color: ${dark_orange};
  font-weight: 500;
  margin-top: 8px;
`;

function formatDate(dateStr) {
  // Split the input date string into its components
  const [year, month, day] = dateStr.split("-");

  // Return the formatted date string in MM/DD/YYYY format
  return `${month}/${day}/${year}`;
}

function checkForGaps(data) {
  var gapObj = {};
  for (let account in data) {
    if (!Array.isArray(data[account])) {
      for (let sc in data[account]) {
        if (data[account][sc].overview.dates.gaps) {
          data[account][sc].overview.dates.gaps.forEach((element) => {
            if (account in gapObj) {
              gapObj[account] = [...gapObj[account], ...[element]];
            } else {
              gapObj[account] = [element];
            }
          });
        }
      }
    }
  }
  return gapObj;
}

function checkForLowConf(data) {
  for (let account in data) {
    if (!Array.isArray(data[account])) {
      for (let sc in data[account]) {
        for (let element of data[account][sc].data) {
          if (element.confidence < 0.9) {
            return true;
          }
        }
      }
    }
  }
  return false;
}

function renderGaps(gaps) {
  const gapMessages = [];

  for (let act in gaps) {
    if (gaps[act].length > 0) {
      let message = `Account ${act}: Missing data from ${formatDate(
        gaps[act][0].startDate
      )} to ${formatDate(gaps[act][0].endDate)}`;

      if (gaps[act].length > 1) {
        gaps[act].forEach((element, i) => {
          if (i > 0) {
            message += `, ${formatDate(element.startDate)} to ${formatDate(
              element.endDate
            )}`;
          }
        });
      }

      gapMessages.push(
        <div key={act}>
          <ListItem>{message}</ListItem>
        </div>
      );
    }
  }

  return gapMessages;
}

function checkForNoUsage(data) {
  for (let account in data) {
    if (!Array.isArray(data[account])) {
      for (let sc in data[account]) {
        for (let element of data[account][sc].data) {
          if (element.usage === null || element.usage === undefined) {
            return true;
          }
        }
      }
    }
  }
  return false;
}

const warningMessage = (data, unsupported) => {
  const gaps = checkForGaps(data);
  return (
    <>
      {Object.keys(gaps).length > 0 && !isNil(data) && (
        <>
          <NoteHeading>
            <NoteIcon src={Hazard} />
            Billing periods are missing in the uploaded documents. This may
            affect the accuracy of our analysis.
          </NoteHeading>
          <NoteText>
            <UnorderedList>
              <div>{renderGaps(gaps)}</div>
            </UnorderedList>
          </NoteText>
        </>
      )}
      {checkForLowConf(data) && (
        <>
          {Object.keys(gaps).length > 0 && (
            <div style={{ height: 16, width: "100%" }}></div>
          )}
          <NoteHeading>
            <NoteIcon src={Hazard} />
            The quality of one or more uploaded documents is low, and some
            information may not have been accurately extracted. Please review
            any values for the highlighted bills.
          </NoteHeading>
        </>
      )}
      {unsupported.length > 0 && (
        <>
          {(checkForLowConf(data) || Object.keys(gaps).length > 0) && (
            <div style={{ height: 16, width: "100%" }}></div>
          )}
          <NoteHeading>
            <NoteIcon src={Hazard} />
            This upload contained documents which did not appear to be energy or
            water bills.
          </NoteHeading>
          <NoteText>
            <UnorderedList>
              <div>
                {unsupported.map((el) => {
                  return <ListItem>{el.filename}</ListItem>;
                })}
              </div>
            </UnorderedList>
          </NoteText>
        </>
      )}
      {checkForNoUsage(data) && (
        <>
          {(unsupported.length > 0 ||
            checkForLowConf(data) ||
            Object.keys(gaps).length > 0) && (
            <div style={{ height: 16, width: "100%" }}></div>
          )}
          <NoteHeading>
            <NoteIcon src={Hazard} />
            This upload appears to have bills without energy usage information,
            or where usage information was not able to be extracted.
          </NoteHeading>
        </>
      )}
    </>
  );
};

function checkForErrors(data, unsupported) {
  if (checkForLowConf(data)) {
    return true;
  }
  if (unsupported.length > 0) {
    return true;
  }
  if (checkForNoUsage(data)) {
    return true;
  }
  if (Object.keys(checkForGaps(data)).length > 0) {
    return true;
  }
  return false;
}

const WarningNote = ({ data, unsupported }) => {
  const [open, setOpen] = useState(checkForErrors(data, unsupported));

  function handleClick() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <div style={{ display: checkForErrors(data, unsupported) ? "" : "none" }}>
      <div
        onClick={() => {
          handleClick();
        }}
      >
        <HazardButton />
      </div>
      <Popover
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Note
          backgroundcolor={light_orange}
          bordercolor={dark_orange}
          fontcolor={dark_orange}
        >
          {warningMessage(data, unsupported)}
        </Note>
      </Popover>
    </div>
  );
};

export default WarningNote;
